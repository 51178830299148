import React, { useEffect } from 'react';
import useAuth from '../src/providers/Auth/Auth.provider';
import FullPageSpinner from './components/TwFullPageProgress/TwFullPageProgress';
import * as Sentry from '@sentry/react';

if ('REACT_APP_DSN' in process.env) {
  Sentry.init({
    dsn: process.env.REACT_APP_DSN,
    tracesSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: process.env.REACT_APP_VERSION
  });
}

const lazyRetry = function <T>(appName: string, componentImport: () => Promise<T>): Promise<T> {
  return new Promise((resolve, reject) => {
    // Check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(`TB-${appName}-retry-lazy-refreshed`) || 'false'
    ) as boolean;

    // Try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(`TB-${appName}-retry-lazy-refreshed`, 'false'); // Success, so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        console.error('Error loading component:', error);
        if (!hasRefreshed) {
          // Not been refreshed yet
          window.sessionStorage.setItem(`TB-${appName}-retry-lazy-refreshed`, 'true'); // Set refresh flag
          window.location.replace(window.location.href); // Perform a hard reload
        } else {
          reject(error); // Default error behavior after trying refresh
        }
      });
  });
};

const PublicApp = React.lazy(() => lazyRetry('public-app', () => import('./PublicApp')));
const App = React.lazy(() => lazyRetry('app', () => import('./App')));

function TruckbaseApp(): JSX.Element {
  const {
    data: { userAuthorized: isAuth }
  } = useAuth();

  return (
    <React.Suspense fallback={<FullPageSpinner />}>
      {isAuth ? <App /> : <PublicApp />}
    </React.Suspense>
  );
}

export default TruckbaseApp;
