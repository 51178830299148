import { TripStop } from '../trips/trips.model';
import {
  Equipment,
  LatestEquipmentReadingInfo,
  ELDConnectionStatus
} from '../equipment/equipment.model';
import { Driver } from '../driver/driver.model';
import { ITagAttributes } from '../loadTags/loadTags.model';
import { BaseQueryParams } from '..';

// DOMAIN LAYER

export enum LastStopTag {
  west = 'WEST',
  east = 'EAST',
  outbound = 'OUTBOUND'
}

export enum AvailabilityType {
  driver = 'DRIVER',
  truck = 'TRUCK',
  trailer = 'TRAILER'
}

export enum AvailabilityStatus {
  available = 'AVAILABLE',
  dispatched = 'DISPATCHED',
  unavailable = 'UNAVAILABLE'
}

export interface Availability {
  start_date?: string;
  end_date?: string;
  type: AvailabilityType;
  status: AvailabilityStatus;
  name: string;
  driver_initials?: string;
  current_trip_id: string;
  final_trip_id: string;
  last_completed_stop: TripStop;
  next_stop: TripStop;
  final_stop: TripStop;
  last_stop_tags: ITagAttributes[];
  id?: string;
  current_trip_readable_id?: number;
  current_order_id?: number;
  final_trip_readable_id?: number;
  current_trip_sort_order?: number;
  final_trip_sort_order?: number;
  current_order_readable_id: number;
  final_order_readable_id: number;
  eld_connection_status: ELDConnectionStatus;
  latest_reading?: LatestEquipmentReadingInfo;
  unavailable_start_date?: string;
  unavailable_end_date?: string;
}

export interface AvailabilitySearchRequest extends BaseQueryParams {
  start_date?: string;
  end_date?: string;
  entity_ids?: string[];
  type?: AvailabilityType;
  status?: AvailabilityStatus;
  tenant_id?: string;
  last_completed_stop_location_queries?: LocationQuery[];
  next_stop_location_queries?: LocationQuery[];
  final_stop_location_queries?: LocationQuery[];
  last_stop_tag_ids?: string[];
}

// APPLICATION LAYER

interface Mapper<T> {
  to_dto: (entity: T) => AvailabilityDTO;
  from_dto: (dto: AvailabilityDTO) => T;
}

export interface AvailabilityDTO {
  id?: string;
  start_date?: string;
  end_date?: string;
  type: AvailabilityType;
  status: AvailabilityStatus;
  name: string;
  driver_initials?: string;
  current_trip_id: string;
  current_order_id?: number;
  final_trip_id: string;
  last_completed_stop: TripStop;
  next_stop: TripStop;
  final_stop: TripStop;
  last_stop_tags: ITagAttributes[];
  current_trip_readable_id?: number;
  final_trip_readable_id?: number;
  current_trip_sort_order?: number;
  final_trip_sort_order?: number;
  current_order_readable_id: number;
  final_order_readable_id: number;
  eld_connection_status: ELDConnectionStatus;
  latest_reading?: LatestEquipmentReadingInfo;
  unavailable_start_date?: string;
  unavailable_end_date?: string;
}

export class AvailabilityMapper implements Mapper<Availability> {
  to_dto(entity: Availability): AvailabilityDTO {
    return {
      id: entity.id,
      start_date: entity.start_date,
      end_date: entity.end_date,
      type: entity.type,
      status: entity.status,
      name: entity.name,
      driver_initials: entity.driver_initials,
      current_trip_id: entity.current_trip_id,
      current_order_id: entity.current_order_id,
      final_trip_id: entity.final_trip_id,
      last_completed_stop: entity.last_completed_stop,
      next_stop: entity.next_stop,
      final_stop: entity.final_stop,
      last_stop_tags: entity.last_stop_tags,
      current_trip_readable_id: entity.current_trip_readable_id,
      final_trip_readable_id: entity.final_trip_readable_id,
      current_trip_sort_order: entity.current_trip_sort_order,
      final_trip_sort_order: entity.final_trip_sort_order,
      current_order_readable_id: entity.current_order_readable_id,
      final_order_readable_id: entity.final_order_readable_id,
      latest_reading: entity.latest_reading,
      eld_connection_status: entity.eld_connection_status,
      unavailable_end_date: entity.unavailable_end_date,
      unavailable_start_date: entity.unavailable_start_date
    };
  }

  from_dto(dto: AvailabilityDTO): Availability {
    return {
      id: dto.id,
      start_date: dto.start_date,
      end_date: dto.end_date,
      type: dto.type,
      status: dto.status,
      name: dto.name,
      driver_initials: dto.driver_initials,
      current_trip_id: dto.current_trip_id,
      current_order_id: dto.current_order_id,
      final_trip_id: dto.final_trip_id,
      last_completed_stop: dto.last_completed_stop,
      next_stop: dto.next_stop,
      final_stop: dto.final_stop,
      last_stop_tags: dto.last_stop_tags,
      latest_reading: dto.latest_reading,
      current_trip_readable_id: dto.current_trip_readable_id,
      final_trip_readable_id: dto.final_trip_readable_id,
      current_trip_sort_order: dto.current_trip_sort_order,
      final_trip_sort_order: dto.final_trip_sort_order,
      current_order_readable_id: dto.current_order_readable_id,
      final_order_readable_id: dto.final_order_readable_id,
      eld_connection_status: dto.eld_connection_status,
      unavailable_end_date: dto.unavailable_end_date,
      unavailable_start_date: dto.unavailable_start_date
    };
  }
}

// Define LocationQuery interface
export interface LocationQuery {
  type: 'city' | 'state' | 'zip' | 'name' | 'country' | 'id';
  value: string;
  displayName: string;
}

// PRESENTATION LAYER

export interface AvailabilitySearchState {
  start_date?: string;
  end_date?: string;
  entities: Driver[] | Equipment[];
  type?: AvailabilityType;
  status?: AvailabilityStatus;
  tenant_id?: string;
  last_completed_stop_location_queries?: LocationQuery[];
  next_stop_location_queries?: LocationQuery[];
  final_stop_location_queries?: LocationQuery[];
  current_order_id?: string;
  final_order_id?: string;
  last_stop_tag_ids?: string[];
}

export interface AvailabilitySearchRequest {
  start_date?: string;
  end_date?: string;
  entity_ids?: string[];
  type?: AvailabilityType;
  status?: AvailabilityStatus;
  tenant_id?: string;
  last_completed_stop_location_queries?: LocationQuery[];
  next_stop_location_queries?: LocationQuery[];
  final_stop_location_queries?: LocationQuery[];
  last_stop_tag_ids?: string[];
}

export interface AvailabilitySearchResponse {
  data: AvailabilityDTO[];
  total: number;
  more_records: boolean;
}
